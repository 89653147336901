import React from "react";

const experienceContent = [
  {
    year: "   2019 - ",
    position: " Softwareudvikler",
    compnayName: "EG A/S",
    details: `EG er en softwarevirksomhed der leverer Nordisk branchesoftware, som giver kunderne 
mulighed for at videreudvikle deres virksomhed. EG udvikler, leverer og servicerer deres egen 
software til mere end 21.000 kunder i den private og offentlige sektor.`,
  },
  {
    year: "2013 - 2019",
    position: " Systemudvikler",
    compnayName: "Sonlinc A/S",
    details: `Siden 1987 har Sonlinc udviklet ERP-løsninger til forsyningssektoren. De første ERPløsninger var målrettet elsektoren, men siden er ERP-løsninger til naturgas, 
fjernvarme, vand og spildevand, renovation, bredbånd og IP-telefoni kommet til.
`,
  },
  {
    year: "2012 - 2012",
    position: "Softwareudvikler (praktik)",
    compnayName: "NKT Photonics A/S",
    details: `NKT Photonics A/S er en sammenlægning af aktiviteterne i Crystal Fibre. Firmaet 
producerer bl.a. fiberlasere, fiberlasersystemer, ultrapræcise lasere, hvidlyskilder,
krystalfibre og komponenter baseret på krystalfibrene. Er bl.a. leverandør til 
vindmølleproducenter, energiselskaber og telecom-industrien m.m`,
  },
  {
    year: "2004 - 2006",
    position: "Selvstændig",
    compnayName: "X-Camp",
    details: `X-Camp er en computercafe, hvor man har mulighed for at surfe på nettet, 
spille computerspil, printe m.m.
`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
